import React, { FC } from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { OfferTemplate } from "@templates/OfferTemplate";
import { graphql } from "gatsby";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";
import { Routes } from "@routes";

const VirtualTour: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");
  const { language } = useI18next();

  let videoLink: string;

  if (language === "en") {
    videoLink =
      "https://www.youtube.com/embed/DU-TljTYlOQ?mute=1&autoplay=0&frameborder=0&loop=1";
  } else if (language === "de") {
    videoLink =
      "https://www.youtube.com/embed/uGr7S4BtgBQ?mute=1&autoplay=0&frameborder=0&loop=1";
  } else {
    videoLink =
      "https://www.youtube.com/embed/SR3sn9sm5Vo?mute=1&autoplay=0&frameborder=0&loop=1";
  }

  return (
    <OfferTemplate
      title={ct("title-offer-virtual-tour")}
      description={ct("description-offer-virtual-tour")}
      heroTitle={t("virtual-tour.hero.title")}
      heroDescription={t("virtual-tour.hero.description", {
        returnObjects: true,
      })}
      heroVideo={videoLink}
    >
      <UniversalSection
        title={t("virtual-tour.sections.0.title")}
        description={t("virtual-tour.sections.0.description", {
          returnObjects: true,
        })}
        fromRight
        video="https://virtual-tour-osiedlepodmiastem.vercel.app/"
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}osiedle-pod-miastem-kunow-1/`}
      />

      <UniversalSection
        title={t("virtual-tour.sections.1.title")}
        description={t("virtual-tour.sections.1.description", {
          returnObjects: true,
        })}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}osiedle-pod-miastem-kunow-1/`}
      />

      <UniversalSection
        title={t("virtual-tour.sections.2.title")}
        description={t("virtual-tour.sections.2.description", {
          returnObjects: true,
        })}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}osiedle-pod-miastem-kunow-1/`}
      />

      <UniversalSection
        title={t("virtual-tour.sections.3.title")}
        description={t("virtual-tour.sections.3.description", {
          returnObjects: true,
        })}
        video="https://cegielniana-rend-tour.vercel.app/?zoomOnlyWithCtrlKey=true"
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}cegielniana3/`}
      />

      <UniversalSection
        title={t("virtual-tour.sections.4.title")}
        description={t("virtual-tour.sections.4.description", {
          returnObjects: true,
        })}
        video="https://www.youtube.com/embed/s5ERYsKDtJE"
        fromRight
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}nowa-farma-warszawa-1/`}
      />

      <UniversalSection
        title={t("virtual-tour.sections.5.title")}
        description={t("virtual-tour.sections.5.description", {
          returnObjects: true,
        })}
        images={data.strapiVisualization.exterior.map(
          (image) => image.localFile.childImageSharp.gatsbyImageData
        )}
      />

      <WhatWeCanDoMore exclude={7} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    strapiVisualization: {
      exterior: {
        localFile: ChildImageSharp;
      }[];
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    firstSectionImage: file(name: { eq: "offer-virtual-tour_0" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    secondSectionImage: file(name: { eq: "offer-virtual-tour_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    strapiVisualization {
      exterior {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default VirtualTour;
